//@ts-nocheck -> many SDK types are out of date here
import { CONVEYANCE_TYPES, type Location } from '@koala/sdk/v4';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { utcToZonedTime, format } from 'date-fns-tz';
import { getFulfillmentTime } from '../menu-availability/conveyance';
import { useHandoffTimes } from '../handoff/time-picker';
import GenericModal from '@/components/uielements/genericModal';
import {
  StyledGenericModalContent,
  StyledGenericModalFooter,
} from '@/components/uielements/genericModal/styles';
import { getStoreTimeInfo } from '@/utils/locations';
import { MODAL } from '@/constants/events';
import { type IBasketState } from '@/types/basket';
import { type IConveyanceModeState } from '@/types/conveyanceMode';
import { type ILocationsState } from '@/types/locations';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import conveyanceModeActions from '@/redux/conveyanceMode/actions';

interface Props {
  basket: IBasketState;
  address: IConveyanceModeState['address'];
  location?: ILocationsState | Location;
  timeWanted: string;
}

export const ConveyanceReconciliation = ({ basket, address, location, timeWanted }: Props) => {
  const {
    isOpen,
    hasRemainingHoursToday,
    storeDate,
    startOfTomorrowInStore: startOfTomorrow,
    storeTimezone,
  } = getStoreTimeInfo(location);

  const { wanted_at } = basket?.checkoutBasket ?? {};

  const [modalShown, setModalShown] = useState(false);
  const dispatch = useDispatch();

  // set initial time wanted
  const dayNeededForReq = wanted_at
    ? format(utcToZonedTime(wanted_at, storeTimezone), 'yyyy-MM-dd')
    : !hasRemainingHoursToday
      ? startOfTomorrow
      : storeDate;

  const { data: handoffTimeData, isSuccess } = useHandoffTimes({
    dayWanted: dayNeededForReq,
    handoffType: CONVEYANCE_TYPES.PICKUP,
    locationId: location?.id,
    supportsAsap: false,
  });

  useEffect(() => {
    setModalShown(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (handoffTimeData?.length > 1 && isSuccess && location?.id) {
      dispatch(
        conveyanceModeActions.setWantedTime(
          handoffTimeData[0],
          location.id,
          CONVEYANCE_TYPES.PICKUP,
        ),
      );
    }
  }, [handoffTimeData, isSuccess, location?.id, dispatch]);

  return (
    <div>
      {/* Alert if outside of business/operating hours */}
      <GenericModal modalOpen={modalShown} name={MODAL.OUTSIDE_OPERARING_HOURS} header={''}>
        <StyledGenericModalContent>
          <div style={{ padding: '40px 20px 20px 20px' }}>
            Your earliest available{' '}
            {
              //@ts-expect-error
              basket?.fulfillment?.type || address?.type || CONVEYANCE_TYPES.PICKUP
            }{' '}
            time is:
            <br></br>
            {getFulfillmentTime(timeWanted, location)}
          </div>
        </StyledGenericModalContent>
        <StyledGenericModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledPrimaryButton
            data-css-override="StyledPrimaryButton"
            aria-label="Start order"
            onClick={() => setModalShown(false)}
          >
            Start Order
          </StyledPrimaryButton>
        </StyledGenericModalFooter>
      </GenericModal>
    </div>
  );
};
