import styled from 'styled-components';

export const Spinner = styled.div`
  height: 22px;
  position: absolute;
  width: 22px;
  /* Spinner Circle Rotation */
  border: 3px rgba(0, 0, 0, 0.25) solid;
  border-top: 3px rgba(0, 0, 0, 1) solid;
  border-radius: 50%;
  -webkit-animation: spCircRot 0.6s infinite linear;
  animation: spCircRot 0.6s infinite linear;
  @-webkit-keyframes spCircRot {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
