import Link from 'next/link';
import { isValid } from 'date-fns';
import { styled } from '@/components/content/stitches';
import { Stack } from '@/components/ui';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { Render } from '@/components/uielements/render';
import { ROUTES } from '@/constants/routes';
import { useOrganization } from '@/features/configs/organization';
import { formatDate } from '@/utils/dates';
import { DATE_FORMAT } from '@/constants/dates';

interface Props {
  timeWanted?: string;
  unavailable: boolean;
  closed: boolean;
}

export function EmptyMenu({ timeWanted, unavailable, closed }: Props) {
  const { data: organizationConfig, isLoading } = useOrganization();
  const isSingleLocation = organizationConfig?.locations.features.isSingleLocation;
  const isSingleLocationEnabled = Boolean(
    isSingleLocation?.enabled && isSingleLocation.storeLocationId,
  );

  return (
    <Stack
      direction="vertical"
      gap="$4"
      css={{
        padding: '$14',
      }}
    >
      <Render condition={closed}>
        <Heading>This location is not currently open. Please come back later.</Heading>
      </Render>
      <Render condition={closed}>
        <Paragraph>Please check back later or contact the store for more information.</Paragraph>
      </Render>

      <Render condition={unavailable}>
        <Heading>
          Online ordering is not currently available
          {timeWanted &&
            isValid(timeWanted) &&
            ` for ${formatDate(timeWanted, DATE_FORMAT.FULL_LABEL)}`}
          .
        </Heading>
      </Render>
      <Render condition={unavailable}>
        <Paragraph>
          While we&rsquo;re unavailable right now, you can still place an order for the future.
          Please use the module above to schedule your order
        </Paragraph>
        <Render condition={!isSingleLocationEnabled && !isLoading}>
          <Link href={ROUTES.STORES} passHref={true} legacyBehavior>
            <StyledPrimaryButton data-css-override="StyledPrimaryButton" as="a">
              Find a location
            </StyledPrimaryButton>
          </Link>
        </Render>
      </Render>
    </Stack>
  );
}

const Heading = styled('h2', {
  fontSize: '$text400',
  fontWeight: 'bold',
  textAlign: 'center',
  margin: 0,
});

const Paragraph = styled('p', {
  fontSize: '$text200',
  textAlign: 'center',
  margin: 0,
});
