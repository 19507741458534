import { type Category, type MenuProduct, CONVEYANCE_TYPES } from '@koala/sdk';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styled from 'styled-components';
import { ConveyanceReconciliation } from '../menu/ConveyanceReconciliation';
import { EmptyMenu } from '../menu/empty';
import { genericEventHandler } from '@/analytics/events';
import { GlobalEvents } from '@/analytics/events/constants';
import { AllergensModal } from '@/components/allergens';
import BackToTopCTA from '@/components/backToTopCTA';
import { FeatureAccessor } from '@/components/featureAccessor';
import { ReorderList } from '@/components/reorders/list';
import { StoreBanner } from '@/components/store/banner';
import CartReconciliation from '@/components/store/cartReconciliation';
import { FilterNavigation } from '@/components/store/foodHall/nav';
import { StoreHero } from '@/components/store/hero';
import {
  StyledHeroMenuSearch,
  StyledMenuLayoutHeader,
  StyledMobileMenuSearch,
  StyledStoreReorders,
  BottomStoreBanner,
  TopStoreBanner,
} from '@/components/store/layout/styles';
import { MenuSearch } from '@/components/store/search';
import Icon from '@/components/uielements/icon';
import { Render } from '@/components/uielements/render';
import { PRODUCT_LOCATION_LABELS } from '@/constants/checkout';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { FEATURE_FLAGS, FLAG_RELATIONSHIP } from '@/constants/features';
import { KOALA_SESSION_STORAGE_KEYS } from '@/constants/global';
import { CART_MIGRATION_TYPES } from '@/constants/locations';
import { CATEGORY_NAV_DISPLAY } from '@/constants/menu';
import { LAYOUT } from '@/constants/styles';
import { foodHallsSliceSelector } from '@/features/food-halls/service';
import { MenuCategories } from '@/features/menu/categories';
import { useMenu } from '@/features/menu/service';
import { Disclaimers } from '@/features/store/disclaimers';
import { MenuNavigation } from '@/features/store/navigation';
import { PopularItems } from '@/features/store/popular-items';
import { useDispatch, useSelector } from '@/redux';
import { selectConveyance } from '@/redux/conveyanceMode/reducer';
import customizeActions from '@/redux/customize/actions';
import globalActions from '@/redux/global/actions';
import { useGlobalDispatch } from '@/redux/global/reducer';
import { locationsActions } from '@/redux/locations/actions';
import {
  getSelectedPopularItem,
  removeSelectedPopularItem,
} from '@/services/recommendations.service';
import { type IKoalaMetadata } from '@/types/global';
import { supportsBrandId } from '@/utils/config';
import { resizeImage } from '@/utils/imageHelper';
import { checkLocationStatus, type StoreRouteParams } from '@/utils/locations';
import { updateStoreMenuUrl } from '@/utils/menu';
import { safelyGetString } from '@/utils/stringHelpers';
import { Spinner } from '@/components/uielements/spinner';

interface Props {
  routeParams: StoreRouteParams;
}

export function StoreDetails({ routeParams }: Props) {
  const { basket, locations } = useSelector((state) => state.app);
  const { strings, webConfig } = useSelector((state) => state.app.cmsConfig);
  const { address } = useSelector((state) => state.app.conveyanceMode);
  const { cartMigration, fulfillmentModal } = useSelector((state) => state.app.global);
  const { activeTagIds } = useSelector(foodHallsSliceSelector);
  const { time_wanted } = useSelector(selectConveyance);
  const dispatch = useDispatch();
  const { displayErrorToast } = useGlobalDispatch();
  const selectedPopularItem = getSelectedPopularItem();
  const router = useRouter();

  const {
    categories: menuCategories,
    isLoading,
    status,
  } = useMenu({
    id: locations?.detail?.id ?? 0,
    wantedAt: time_wanted,
  });

  const foodHallEnabled = webConfig.admin.food_hall ?? false;

  const useBrandId = supportsBrandId(webConfig);
  const hasAvailableProducts = Boolean(
    menuCategories.some((category) => category.products.length > 0),
  );

  const customizeProduct = (item: MenuProduct, category: Category, urlTrigger?: boolean) => {
    // Don't update the URL if the PDP is already being triggered from the URL
    if (!urlTrigger) {
      const url = updateStoreMenuUrl(
        {
          // @ts-expect-error ensure that locationDetail is defined.
          id: useBrandId ? locations.detail?.brand_id : locations.detail?.id,
          name: locations.detail?.label,
          catId: category.id.toString(),
          catName: category.name,
          productId: item.id,
          productName: item.name,
        },
        activeTagIds,
      );
      void router.replace(url, undefined, { shallow: true });
    }

    // If the basket location differs from the current menu location and
    // the current basket contains items, trigger the basket reset modal
    if (
      basket.location.id !== locations.detail?.id &&
      basket.content &&
      basket.content.basket_items.length
    ) {
      dispatch(globalActions.setCartMigration(CART_MIGRATION_TYPES.RESET, item));
      return;
    }

    dispatch(
      customizeActions.setProduct({
        product: item,
        menuCategories,
        label: PRODUCT_LOCATION_LABELS.MENU,
      }),
    );
  };

  const verifyFulfillmentAddress = () => {
    if (cartMigration.type !== null || fulfillmentModal.display) {
      return;
    }

    if (locations.detail?.is_delivery_only && !basket.fulfillment?.address && !address) {
      dispatch(
        globalActions.toggleFulfillmentModal(
          true,
          locations.detail,
          CONVEYANCE_TYPES.DELIVERY,
          false,
          // @ts-expect-error differentiate between `null` and `undefined`.
          null,
          false,
          true,
        ),
      );
    }
  };

  useEffect(() => {
    if (!menuCategories.length || !selectedPopularItem) {
      return;
    }

    let category: Category | undefined = undefined;
    let product: MenuProduct | undefined = undefined;

    menuCategories.forEach((categoryItem) => {
      categoryItem.products.forEach((productItem: MenuProduct) => {
        if (productItem.id === selectedPopularItem) {
          category = categoryItem;
          product = productItem;
        }
      });
    });

    if (product && category) {
      customizeProduct(product, category, true);
    } else {
      dispatch(displayErrorToast("Sorry, the selected item isn't available at this location"));
    }

    // always reset the selected item if we get to this stage
    removeSelectedPopularItem();

    // we cannot add `customizeProduct` as a depedency or else it will create an infinite render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPopularItem, menuCategories]);

  useEffect(() => {
    checkLocationStatus(locations.detail);

    const lastUpdatedLocationAt = document.head.querySelector(
      'meta[name="location:last-updated-at"]',
    ) as unknown as HTMLMetaElement | null;

    if (locations.detail?.id && lastUpdatedLocationAt) {
      dispatch(
        locationsActions.fetchLocationIfModified(
          locations.detail.id,
          lastUpdatedLocationAt.content,
        ),
      );
    }

    // Trigger product modal
    const validCategory: Category | undefined = menuCategories.find(
      (category) => category.id === routeParams.catId,
    );

    if (validCategory) {
      const validProduct: MenuProduct | undefined = validCategory.products.find(
        (product) => product.id === routeParams.productId,
      );

      if (validProduct) {
        customizeProduct(validProduct, validCategory, true);
      }
    }

    // Assemble koalaMetadata object, if necessary
    const koalaMetadata: IKoalaMetadata = {};

    // Table number from query params
    const table_number = router?.query?.table_number;

    if (table_number) {
      if (Array.isArray(table_number)) {
        koalaMetadata.table_number = table_number[0];
      } else {
        koalaMetadata.table_number = table_number;
      }
    }

    // Location ID and label
    if (!basket.location.id) {
      // @ts-expect-error differentiate between `null` and `undefined`.
      koalaMetadata.location_id = locations.detail.id;
      koalaMetadata.location_label = routeParams.name;
      // @ts-expect-error differentiate between `null` and `undefined`.
      koalaMetadata.location_brand_id = locations.detail.brand_id;
    }

    // If koalaMetadata has keys, write them to session storage
    if (Object.keys(koalaMetadata).length > 0) {
      sessionStorage.setItem(KOALA_SESSION_STORAGE_KEYS.METADATA, JSON.stringify(koalaMetadata));
    }

    // KA Event
    genericEventHandler(GlobalEvents.STORE__MENU_VIEW, {
      name: routeParams.name,
      // @ts-expect-error differentiate between `null` and `undefined`.
      details: locations.detail.id.toString(),
    });
  }, []);

  useEffect(() => {
    checkLocationStatus(locations.detail);
    verifyFulfillmentAddress();
  });

  return (
    <>
      {/* If there are no products available at this location, show the no products view */}
      <ConveyanceReconciliation
        timeWanted={time_wanted ?? ''}
        basket={basket}
        address={address}
        location={locations.detail}
      />

      <Render condition={status === 'loading'}>
        <div>
          <StoreHero />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              padding: '2rem',
            }}
          >
            <Spinner style={{ height: '50px', width: '50px' }} />
          </div>
        </div>
      </Render>

      <Render condition={hasAvailableProducts}>
        <TopStoreBanner>
          <StoreBanner />
        </TopStoreBanner>

        <StoreHero />

        {/* Menu Search */}
        <FeatureAccessor featureFlag={FEATURE_FLAGS.STORE__MENU_SEARCH}>
          <StyledMobileMenuSearch
            className={CSS_CLASSES.STORE_MENU.MENU_SEARCH_MOBILE}
            $foodHallEnabled={foodHallEnabled}
          >
            <MenuSearch onSelect={customizeProduct} isMobile={true} />
          </StyledMobileMenuSearch>
        </FeatureAccessor>

        {/* Food Hall Nav if food hall enabled */}

        <Render condition={foodHallEnabled}>
          <FilterNavigation menuCategories={menuCategories} />
        </Render>

        {/* Menu Items */}
        {locations.detail?.id && (
          <Container>
            <MenuNavigation
              categories={menuCategories}
              activeTagIds={activeTagIds}
              locationDetail={locations.detail}
            />

            <MenuLayout data-css-override="StyledMenuLayout">
              <div>
                {/* Allergens Modal and hero menu search if hero nav only */}
                <FeatureAccessor
                  featureFlag={[
                    FEATURE_FLAGS.STORE__MENU_SEARCH,
                    FEATURE_FLAGS.STORE__DIETARY_PREFERENCES,
                  ]}
                  flagRelationship={FLAG_RELATIONSHIP.OR}
                >
                  <StyledMenuLayoutHeader>
                    <FeatureAccessor featureFlag={FEATURE_FLAGS.STORE__MENU_SEARCH}>
                      <StyledHeroMenuSearch className={CSS_CLASSES.STORE_MENU.MENU_SEARCH}>
                        <MenuSearch onSelect={customizeProduct} />
                      </StyledHeroMenuSearch>
                    </FeatureAccessor>

                    <FeatureAccessor featureFlag={FEATURE_FLAGS.STORE__DIETARY_PREFERENCES}>
                      <AllergensModal maxWidth={875} $fromTop={30} displayOnMobile={false} />
                    </FeatureAccessor>
                  </StyledMenuLayoutHeader>
                </FeatureAccessor>

                <FeatureAccessor featureFlag={FEATURE_FLAGS.ME__REORDER}>
                  <StyledStoreReorders>
                    <ReorderList
                      /** @TODO ensure that `detail` is defined. */
                      locationID={locations.detail.id}
                      ordersToDisplay={3}
                      collapsible={false}
                    />
                  </StyledStoreReorders>
                </FeatureAccessor>

                <PopularItems location={locations} menuCategories={menuCategories} />

                {/*
                  If food hall, wait until we've set an active tag id before rendering the menu
                  This helps preserve category linking
                */}
                {(!foodHallEnabled || activeTagIds) && menuCategories && (
                  <MenuCategories
                    categories={menuCategories}
                    customizeProduct={customizeProduct}
                    currentCategory={routeParams.catId}
                    activeTagIds={activeTagIds}
                  />
                )}
              </div>

              {/* Menu and Location Disclaimers, if they exist */}
              <Disclaimers />
            </MenuLayout>
          </Container>
        )}

        {/* Cart migration from one location to another */}
        <CartReconciliation menuCategories={menuCategories} menuLoading={isLoading} />

        {webConfig.store_banner.placement === 'bottom' && (
          <BottomStoreBanner>
            <StoreBanner />
          </BottomStoreBanner>
        )}

        {safelyGetString(strings, 'store.back_to_top_cta') && !isLoading && <BackToTopCTA />}

        <MenuLoading $isVisible={isLoading}>
          <Icon.Loading />
        </MenuLoading>
      </Render>

      <Render condition={!hasAvailableProducts && status !== 'loading'}>
        <StoreHero />
        <EmptyMenu
          timeWanted={time_wanted}
          unavailable={Boolean(time_wanted)}
          closed={!Boolean(time_wanted)}
        />
      </Render>
    </>
  );
}

const Container = styled.div`
  display: block;

  ${({ theme }) =>
    theme.menu_categories.category_nav_display === CATEGORY_NAV_DISPLAY.LEFT &&
    `
      display: grid;
      grid-template-columns: minmax(14rem, 18rem) 1fr;

      > div:first-child {
        width: ${LAYOUT.LEFT_CATEGORY_NAV_MAX_WIDTH}px;
        min-width: ${LAYOUT.LEFT_CATEGORY_NAV_MIN_WIDTH}px;
        overflow-y: auto;
        position: sticky;
        top: ${LAYOUT.HEADERHEIGHT}px;
        align-self: flex-start;
        min-height: calc(100vh - ${LAYOUT.HEADERHEIGHT}px);
        justify-content: left;
      }
    `}

  @media (max-width: 767px) {
    display: block;
  }
`;

function getBackgroundRepeatValue(value: 'none' | 'vertical' | 'horizontal' | 'repeat') {
  const map = {
    none: 'no-repeat',
    vertical: 'repeat-y',
    horizontal: 'repeat-x',
    repeat: 'repeat',
  };

  return map[value];
}

const MenuLayout = styled.div(({ theme }) => ({
  backgroundImage: theme.menu.background.image
    ? `url(${resizeImage(theme.menu.background.image, { width: 1800 })})`
    : 'none',
  backgroundSize: theme.menu.background.size,
  backgroundRepeat: getBackgroundRepeatValue(theme.menu.background.repeat),
  backgroundAttachment: theme.menu.background.attachment,
  backgroundPosition: `${theme.menu.background.horizontal_position} ${theme.menu.background.vertical_position}`,
  margin: '0 auto',
  padding: 'var(--size-3) var(--size-4)',
  width: '100%',

  '@media screen and (min-width: 768px)': {
    flex: '1 1 auto',
    gap: 'var(--size-8)',
    padding: 'var(--size-8)',
    overflow: 'hidden',
  },

  '@media screen and (min-width: 1024px)': {
    padding: 'var(--size-12) var(--size-16)',
  },

  '& > div:first-child': {
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--size-6)',
    margin: '0 auto',
    maxWidth: 'var(--size-4xl)',

    '@media screen and (min-width: 1024px)': {
      gap: 'var(--size-12)',
    },
  },

  '& > h1': {
    fontSize: '24px',
  },

  '& > p': {
    fontSize: '16px',
  },
}));

const MenuLoading = styled.div<{ $isVisible: boolean }>(({ theme, $isVisible }) => ({
  alignItems: 'center',
  backgroundColor: theme.global.body_color,
  border: '1px solid',
  borderColor: theme.global.primary_border_color ?? 'transparent',
  boxShadow: '0px 0px 8px 0px rgb(15 15 15 / 0.19)',
  bottom: '1.25rem',
  color: theme.global.primary_active_color,
  display: 'flex',
  borderRadius: '50%',
  right: '1.25rem',
  height: '3rem',
  justifyContent: 'center',
  position: 'fixed',
  transform: $isVisible
    ? 'translateY(calc(var(--safe-offset--descending, 0) * -1))'
    : 'translateY(10rem)',
  transition: 'all 0.4s ease',
  width: '3rem',
  zIndex: 10,
}));
